// Media Breakpoint Variables
$breakpoint-mobil: 375px;
$breakpoint-mobilX: 480px;
$breakpoint-tab-mob: 600px;
$breakpoint-tablet: 768px;
$breakpoint-tabletX: 992px;
$breakpoint-desktop: 1200px;
$breakpoint-desktopX: 1480px;
// Media Breakpoint Variables End

/***************************** Media Query Scss *****************************/

@media screen and (max-width: $breakpoint-desktopX) {
}
@media screen and (max-width: $breakpoint-desktop) {
}
@media screen and (max-width: $breakpoint-tabletX) {
  html {
    font-size: 15px;
  }

  .img {
    width: 75px !important;
  }
  .content-left {
    width: 100% !important;
  }
  .content-right {
    display: none !important;
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  .nav-link {
    font-size: 0.8rem !important;
  }

  .logo {
    width: 90px;
  }
}
@media screen and (max-width: $breakpoint-tab-mob) {
  html {
    font-size: 14px;
  }
  #about,
  #partners,
  #gallery,
  #contact,
  #services,
  #activity,
  #consultancy,
  #technological {
    margin-top: 75px !important;
    margin-bottom: 20px !important;
  }

  .header {
    margin: 20px auto !important;
    padding: 0.8rem !important;
  }

  .content-about {
    padding: 20px 10px !important;
  }

  .mail-item {
    display: flex !important;
    flex-direction: column !important;
  }

  .tren {
    margin-top: 10px;
  }
}
@media screen and (max-width: $breakpoint-mobilX) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: $breakpoint-mobil) {
  html {
    font-size: 13px;
  }

  .h-70-vh {
    height: 60vh !important;
  }
}

/***************************** Media Query Scss End *****************************/
