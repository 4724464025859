#about,
#partners,
#contact,
#activity,
#consultancy,
#technological {
  margin-top: 150px;
  margin-bottom: 50px;
  min-height: 100vh;
}

#gallery,
#services {
  margin-top: 150px;
  margin-bottom: 50px;
  height: 100%;
}

.content-about {
  background-color: #fff;
  padding: 30px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
}

.about-text {
  font-size: 1.3rem;
}

.contact-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.content-contact {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.content-left {
  width: 50%;
}

.content-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #f3f3f3;
}

.contact-item a,
.contact-item div {
  text-decoration: none;
  color: $gray;
  margin-left: 10px;
  cursor: pointer;
}

.contact-item a:hover {
  opacity: 0.7;
}

.mail-item {
  margin-left: 0 !important;
}

.footer-item {
  margin-left: 5px;
}

.tren {
  z-index: 1199;
  width: fit-content;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 25px;
  margin-left: auto;
}

.tren button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-top: 3px;
}

.back-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 30px;
}

.back-button:hover {
  opacity: 0.7;
}

.animation-container {
  animation: container 1s ease;
}

.animation-card1 {
  animation: card1 1s ease;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.animation-card2 {
  animation: card2 1.5s ease;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.animation-card3 {
  animation: card3 2s ease;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

@keyframes card1 {
  0% {
    position: relative;
    left: 1000%;
  }
  100% {
    position: relative;
    left: 0;
  }
}

@keyframes card2 {
  0% {
    position: relative;
    left: 1000%;
  }
  100% {
    position: relative;
    left: 0;
  }
}

@keyframes card3 {
  0% {
    position: relative;
    left: 1000%;
  }
  100% {
    position: relative;
    left: 0;
  }
}

@keyframes container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.iframe-videos {
  width: 100%;
  min-height: 330px;
}

.object-position-1 {
  object-position: -330px;
}

.object-position-2 {
  object-position: -50px;
}
