// Loader

.lds-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid $primaryGray;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primaryGray transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Loader End

//header

.header {
  margin: 25px auto;
  text-align: center;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  font-size: 1.3rem;
}

//nav

.nav-link {
  color: $white;
  text-decoration: none;
}

.nav-link:hover {
  opacity: 0.7;
}

.navbar-bg {
  background-color: $gray !important;
}

.nav-link-mob {
  color: #000;
  text-decoration: none;
  width: 100%;
}

.active {
  opacity: 0.7;
}

.homepage-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  opacity: 0.8;
  position: absolute;
}

.jumbotron {
  width: 100%;
  height: 100%;
}

.heading {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  color: #707175;
  padding: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  animation: fadeIn 3s ease;
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.about-text {
  text-align: center;
}

footer {
  width: 100%;
  position: relative;
  bottom: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
